<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-distributor class="mr-2 mt-2" v-model:value="state.params.distributor"></filter-distributor>
        <filter-brands class="mr-2 mt-2" v-model:value="state.params.brand" v-model:brand="state.params.brand" />
        <filter-products class=" mt-2" v-model:value="state.params.product" v-model:brand="state.params.brand" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-date-picker
          v-model:value="state.params.start_date"
          placeholder="Dari Tanggal"
          style="width: 300px"
          class="mr-2 mt-2"
          format="DD MMMM YYYY"
          :disabled-date="disabledStartDate"
        />
        <a-date-picker
          v-model:value="state.params.end_date"
          placeholder="Sampai Tanggal"
          style="width: 300px"
          class="mr-2 mt-2"
          format="DD MMMM YYYY"
          :disabled-date="disabledEndDate"
        />
        <a-select
          :options="state.statusLists"
          class="mt-2"
          style="width: 300px;"
          placeholder="Pilih Status"
          v-model:value="state.params.status"
        ></a-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-input-search
          class="mr-2 mt-2"
          v-model:value="state.params.q"
          placeholder="Cari ..."
          style="width: 300px"
        />
        <a-button class="mt-2" type="primary" title="cari" @click="fetchData" :loading="state.isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <ASpace>
          <a-button
            v-has-access="['report.transaksi-distributor.update']"
            title="Konfirmasi"
            type="primary"
            @click="btnModalInformation()"
          >
            <i class="fa fa-check" aria-hidden="true"></i>
          </a-button>
          <DownloadExcel
              :url="state.endpoint"
              :params="queryParams()"
              namefile="Transaksi-Distributor"
              @errors="errorMessage"/>
        </ASpace>
      </div>
    </div>

    <div class="table-responsive mt-4">
      <md-table
        :columns="state.columns"
        :data-source="state.data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: state.meta.total,
          pageSize: state.meta.per_page,
          current: state.meta.page,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        bordered
        @change="handleTableChange"
        :loading="state.isFetching">
        <template #no="{ index }">
          <span>
            {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
          </span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Detail Transaksi Gudang">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                v-has-access="['report.detail-gudang-distributor']"
                @click="
                  () => {
                    router.push({
                      path: '/laporan/laporan-detail-transaksi-gudang-distributor',
                      query: { distri: record.vendor_id },
                    })
                  }
                ">
                <small>
                  <i class="fe fe-info fa-lg" />
                </small>
              </a>
            </a-tooltip>

            <a-tooltip title="Syncron">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                v-has-access="['report.transaksi-distributor.syncron']"
                @click="btnSyncron(record.id)"
              >
                <small>
                  <i class="fa fa-refresh"></i>
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>

    <!-- modal informasi -->
    <a-modal
      title="Informasi"
      v-model:visible="state.isInformation"
      @ok="btnConfirm"
      :confirm-loading="state.loading"
    >
      <p v-if="state.selectedRowKeys.length === 0">Checklist data yang akan di pilih</p>
      <p v-else>Anda yakin ingin konfirmasi ({{ state.selectedRowKeys.length }}) data?</p>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue'
import apiClient from '@/services/axios'
import { useRouter } from 'vue-router'
import { Modal, message } from 'ant-design-vue'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterBrands from '@/components/filter/FilterBrand'
import FilterProducts from '@/components/filter/FilterProduct'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
  components: {
    DownloadExcel,
    FilterDistributor,
    FilterBrands,
    FilterProducts,
  },
  setup() {
    const router = useRouter()
    const errorMessage = ref(null)
    const state = reactive({
      columns: [
        {
          title: 'No',
          slots: { customRender: 'no' },
        },
        {
          title: 'Bulan',
          dataIndex: 'bulan',
        },
        {
          title: 'Kode Distributor',
          dataIndex: 'distri_code',
        },
        {
          title: 'Nama Distributor',
          dataIndex: 'distri_name',
        },
        {
          title: 'Kode Product',
          dataIndex: 'product_code',
        },
        {
          title: 'Nama Produk',
          dataIndex: 'product_name',
        },
        {
          title: 'UOM',
          dataIndex: 'uom',
        },
        {
          title: 'Stok Awal',
          dataIndex: 'stock_awal',
        },
        {
          title: 'Sell In',
          dataIndex: 'sell_in',
        },

        {
          title: 'Sell Out',
          dataIndex: 'sell_out',
        },
        {
          title: 'Penjualan Dengan ID Toko',
          dataIndex: 'w_idbk',
        },
        {
          title: 'Penjualan Tanpa ID Toko',
          dataIndex: 'n_idbk',
        },
        {
          title: 'Selisih Stok',
          dataIndex: 'selisih',
        },
        {
          title: 'Action',
          slots: { customRender: 'action' },
        },
      ],
      endpoint: '/api/report/transaksi-distributor',
      loading: false,
      isFetching: false,
      isInformation: false,
      data: [],
      params: extractQueryParams({
        q: '',
        product: [],
        brand: [],
        distributor: [],
        start_date:  moment(new Date()).startOf('month'),
        end_date: moment(new Date()).subtract(1, 'days'),
        status: null,
        page: 1,
        'per-page': 10,
      }),
      meta: {
          per_page: 10,
          page: 1,
          total: 0,
      },
      selectedRows: [],
      selectedRowKeys: [],
      statusLists: [
        { value: null, label: 'Semua' },
        { value: 2, label: 'Lolos' },
        { value: 1, label: 'Tidak Lolos' },
      ],
    })

    const handleTableChange = (pag, filters, sorter) => {
      state.params.page = pag.current
      state.params['per-page'] = pag.pageSize
      
      fetchData()
    }

    const onSelectChange = (keys, rows) => {
      state.selectedRowKeys = keys
      state.selectedRows = rows
    }

    const disabledStartDate = (current) => {
      return current && current > moment(state.params.end_date)
    }

    const disabledEndDate = (current) => {
      return current && moment(state.params.start_date) >= current
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.start_date) {
        state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
      }

      if (state.params.end_date) {
        state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
      }

      return updateUrlWithQuery(params)
    }

    const fetchData = () => {
      state.isFetching = true
      apiClient
        .get(state.endpoint, {
          params: queryParams(),
        })
        .then(({ data }) => {
          const { items, _meta } = data

          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const btnModalInformation = () => {
      state.isInformation = true
    }

    const btnConfirm = () => {
      const promises = []
      state.loading = true
      state.selectedRowKeys.forEach(k => {
        promises.push(
          apiClient.post(`${state.endpoint}/${k}?_method=PUT`, {
            active: false,
          }),
        )
      })

      Promise.all(promises)
        .then(() => {
          console.log('completed')
        })
        .finally(err => {
          state.selectedRowKeys = []
          state.isInformation = false
          fetchData()
          state.loading = false
        })
    }

    const btnSyncron = id => {
      Modal.confirm({
        title: 'Konfirmasi syncron data',
        content: 'Apakah anda ingin syncron data transaksi ?',
        onOk() {
          apiClient
            .get(`/api/report/transkasi-distributor/${id}/resynch`)
            .then(({ data }) => {
              state.isInformation = false
              fetchData()
              message.success('Berhasil disimpan')
            })
            .catch(e => message.error('Gagal syncron!'))
        },
        onCancel() {},
      })
    }

    // handle vue
    onMounted(() => {
      fetchData()
    })

    return {
      router,
      errorMessage,
      state,
      queryParams,
      fetchData,
      handleTableChange,
      onSelectChange,
      btnModalInformation,
      btnConfirm,
      btnSyncron,
      disabledStartDate,
      disabledEndDate,
    }
  },
})
</script>
